/* eslint-disable react/no-danger */
// @ts-nocheck
import React from 'react';
import { FormattedHTMLMessage, injectIntl, useIntl, } from 'gatsby-plugin-intl';
import parse from 'html-react-parser';
import 'animate.css/animate.min.css';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Button from '../components/Button';
import SEO from '../components/Seo';
import Title from '../components/Title';
import heroImage from '../images/banner-2.jpg';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: #074166;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  margin: 1em 0 2em 0;
`;

const FAQTitle = styled.h3`
  & {
    color: #074166;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
`;

const FAQContainer = styled.div`
  @media (min-width: 992px) {
    width: 65%;
    margin: auto;
  }
`;

const FAQPlaceholder = styled.div`
  padding-bottom: 1.5em;
`;

const Blockquote = styled.blockquote`
  margin-bottom: 0em;
  & h3 {
    display: inline-block;
  }
`;

const FAQPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="FAQ" />
      <Wrapper className="container-fluid">
        <Title
          imageUrl={heroImage}
          position="80% 50%"
          text={intl.formatMessage({ id: 'faq.hero_text' })}
        />
        <Heading>{intl.formatMessage({ id: 'faq.title' })}</Heading>
        <FAQContainer>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_1.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_1.answer' }))}
          </FAQPlaceholder>

          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_2.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_2.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_3.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_3.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_4.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_4.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_5.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_5.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_6.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_6.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_7.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_7.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_8.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_8.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_9.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_9.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_10.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_10.answer' }))}
          </FAQPlaceholder>
          <FAQPlaceholder>
            <Blockquote>
              <FAQTitle>
                {intl.formatMessage({ id: 'faq.question_11.title' })}
              </FAQTitle>
            </Blockquote>

            {parse(intl.formatHTMLMessage({ id: 'faq.question_11.answer' }))}
          </FAQPlaceholder>
        </FAQContainer>
        <Button mt={1} mb={2} to="/apply">Apply Now</Button>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(FAQPage);
